

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';
const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;


const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Profhilofaq({setIsModalOpen}) {
    return (
      <>
      <Separator />
 
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 pt-3 pb-3pt-md-5">
      <Container className="pb-md-5 pt-md-5">
        <Row className="justify-content-center pb-5">
          <Col lg="12">
   
            <Iwrap>
<Itext as="h2">Questions about <span>Profhilo</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       


        <Col  lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
     
     >
           <Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        What areas can be treated by Profhilo?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Profhilo is particularly effective when treating the face, neck and decolletage, and hands.  
The face is the most commonly treated location, with 5 Bio Aesthetic Points (BAP) injected on each side to maximise diffusion in the cheeks and lower face to obtain the best results, minimise discomfort, and ensure safety.


        </p>
    </AccordionItemPanel>
</AccordionItem> 




</Accordion>    <br />
         


           <Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        What can be achieved with Profhilo London?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patients can expect enhanced skin firmness, elasticity, hydration, and overall improvement in the quality of the skin, with the results lasting 6 months. This is determined by the quality of the skin as well as the patients age and lifestyle factors.


        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>  

<br />


      
<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is Profhilo London painful?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patients usually find the level of discomfort to be minimum and very manageable. We understand that everyone's pain tolerance is different, and we are happy to provide anaesthetic cream to our patients before the treatment begins.<br /> <br />

There may be slight discomfort after the treatment, which is to be expected, but there should be no substantial pain. Post treatment advice will be addressed and a copy will be sent directly to your email, to ensure the patient is aware of what to expect and aftercare to follow. 


        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>  


<br />


      
<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        What happens on the day?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
    <p>Session begins with a thorough tailored consultation which consists of an assessment of your skin, medical status and expectations of the treatment. A tailored treatment plan is then devised according to the assessment along with what to expect during and after the treatment. <br /><br />

Procedure follows once you are deemed suitable for the treatment. Anaesthetic cream can be applied if needed/upon request.<br /><br /> 

After disinfecting the area to be treated, points of injection will be marked and Profhilo will be injected slowly and gradually.<br /><br />

Following the injecting of the product, we will perform a gentle massage to smooth the treated area. <br /><br />

Post treatment advice will be addressed and a copy will be sent directly to your email.<br /><br />

Each session can be completed in 15-20 minutes, including consultation time. </p>


    </AccordionItemPanel>
</AccordionItem>



</Accordion>  


<br />
   
<Accordion allowZeroExpanded >
        <AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        What filler, facials can be done with Profhilo on the day? 
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Dermal filler treatments: as Profhilo treatment is injected into the areas around the cheeks, under eyes, jaws and chin – these areas cannot be treated with fillers on the same day as we need to allow the hyaluronic acid from the skin booster to diffuse and settle before further treatments can be introduced. Although patients can go ahead with the Non-Surgical Rhinoplasty, Lip Filler and Smile line treatments on the day as this does not affect the Profhilo treatment. <br /><br /> 

Anti-wrinkle treatments:  Patients can go ahead with anti-wrinkle treatments with Profhilo on the day. Masseter treatment can only be done 2 weeks before or after Profhilo and not on the day.<br /><br />  

Facials: Vampire Facial, Microneedling and Mesotherapy can be done 2 weeks before or after Profhilo, and to be avoided on the same day. 


      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>  

         
<br />
        
<Accordion allowZeroExpanded >
        <AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is Profhilo London safe?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes. Profhilo consists of a highly pure grade of hyaluronic acid that is exceedingly biocompatible. Did you know hyaluronic acid is already found in our skin, making the substance itself safe for treatment?

      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>   

<br />

<Accordion allowZeroExpanded >
 
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is there downtime/ recovery time after Profhilo? </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patients can continue their normal daily activities after treatment. There may be redness, slight discomfort, and swelling at the injection site right after treatment, as well as self-limiting lumps at the injection sites. Bruising may be experienced depending on skin sensitivity and other factors. 

        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    

<br />
<Accordion allowZeroExpanded >
 
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        How long does Profhilo work? </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Improvements will be noticed within 7-10 days after the treatment. After completing the course, final results will be seen after 3-4 weeks. Results last 6 months. <br /> <br /> 

2 treatments are needed, spaced 1 month apart. <br /> <br />  

To maintain the result, 1 session will be needed every 6 months. 


        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  

<br />
<Accordion allowZeroExpanded >
 
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is Profhilo worth it? </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        When Profhilo is injected, it will diffuse and form like a blanket of hyaluronic acid in the dermal layer of your skin. It will help make the skin:<br /><br />

-	Hydrated<br />
-	Plumper<br />
-	Tighter<br />
-	Firmer<br /> 
-	Softer<br />

The beauty of Profhilo is that it does not change the structure of the face, but the skin will look more youthful, plump, tight and firm – bringing out the beauty in you. 
 


        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



   


          </Col>



        </Row>
        <br />
              <div onClick={() => setIsModalOpen(true)}>   <Bookbutton>Book Appointment</Bookbutton> </div>
        


       


        </Container>
        </Section>

        <SeparatorEnd />
      </>


    );
}







